import React from "react";
import whatsappIcon from "../../assets/images/icon-whatsapp-verde.svg";
import Layout from "../../components/Layout"
import "./style.css";

export default function Contatos() {

  return (
    <Layout title="Contatos" header balcaoVirtual>
      <p>
        Converse conosco. Temos atendimentos especializados para atender você da melhor forma. <br />
        <span className="contatos-title-description">Verifique abaixo os setores e respectivos contatos:</span>
      </p>
      <div className="contatos mt-4">
        <hr className="line-bottom-container my-5"/>
        <p>
          Gabinete do Procurador Geral<br />
          <a href="mailto:gabinete.pgm@pgm.fortaleza.ce.gov.br" target="_blank">gabinete.pgm@pgm.fortaleza.ce.gov.br</a>
        </p>
        <p>
        <img src={whatsappIcon} alt="WhatsApp" width={24} /> WhatsApp: <a href="https://api.whatsapp.com/send?phone=558532651613" target="_blank">
            (85) 3265-1613
            </a>
        </p>
        <hr className="line-bottom-container my-5"/>
        <p>
          Judiciário e Ministério Público <br />
          <a href="mailto:intimacoesurgentes@pgm.fortaleza.ce.gov.br">intimacoesurgentes@pgm.fortaleza.ce.gov.br</a>
        </p>
        <hr className="line-bottom-container my-5"/>
        <p>
          Procuradoria da Fazenda Pública (Dívida Ativa) <br />
          <a href="mailto:atendimento.da@pgm.fortaleza.ce.gov.br">atendimento.da@pgm.fortaleza.ce.gov.br</a>
        </p>
        <p>
          Telefone Móvel: <span className="font-weight-normal">  (85) 98419-2045 / (85) 98419-2042</span><br />
        </p>
        <hr className="line-bottom-container my-5"/>
        <p>
          Procuradoria Judicial <br />
          <a href="mailto:projud@pgm.fortaleza.ce.gov.br">projud@pgm.fortaleza.ce.gov.br</a>
        </p>
        <p>
          Telefone Móvel: <span className="font-weight-normal">  (85) 3265-1821 / (85) 3265-1840</span><br />
        </p>
        <hr className="line-bottom-container my-5"/>
        <p>
          Procuradoria de Urbanismo e Meio Ambiente <br />
          <a href="mailto:pgm.prourma@pgm.fortaleza.ce.gov.br">pgm.prourma@pgm.fortaleza.ce.gov.br</a>
        </p>
        <p>
          Telefone Móvel: <span className="font-weight-normal">  (85) 3265-4670 / (85) 3265-5248 </span><br />
        </p>
        <p>
        <img src={whatsappIcon} alt="WhatsApp" width={24} /> WhatsApp: <a href="https://api.whatsapp.com/send?phone=5585984007795" target="_blank">
            (85) 98400-7795
            </a>
        </p>
        <hr className="line-bottom-container my-5"/>
        <p>
          Procuradoria Do Processo Administrativo Disciplinar <br />
          <a href="mailto:propad.jp@pgm.fortaleza.ce.gov.br">propad.jp@pgm.fortaleza.ce.gov.br</a>
        </p>
        <p>
          Telefone Móvel: <span className="font-weight-normal">  (85) 3265-7866 </span><br />
        </p>
        <p>
          <img src={whatsappIcon} alt="WhatsApp" width={24} /> WhatsApp: <a href="https://api.whatsapp.com/send?phone=5585986822972" target="_blank">
            (85) 98682-2972
            </a>
          <hr className="line-bottom-container my-5"/>
        </p>
        <p>
          Consultoria Geral <br />
          <a href="mailto:pja@pgm.fortaleza.ce.gov.br">pja@pgm.fortaleza.ce.gov.br</a>
        </p>
        <p>
          Telefone Móvel: <span className="font-weight-normal">  (85) 3265-4447 / (85) 3265-1910</span><br />
        </p>
        <p>
          <img src={whatsappIcon} alt="WhatsApp" width={24} /> WhatsApp: <a href="https://api.whatsapp.com/send?phone=558532651910" target="_blank">
            (85) 3265-1910
            </a>
          <hr className="line-bottom-container my-5"/>
        </p>
        <p>
          Procuradoria de desenvolvimento e Pesquisa/Biblioteca <br />
          <a href="mailto:biblioteca.pgm@gmail.com">biblioteca.pgm@gmail.com</a><span className="font-weight-normal"> / </span><a href="mailto:biblioteca.pgm@hotmail.com">biblioteca.pgm@hotmail.com</a>
        </p>
        <p>
          Telefone Móvel: <span className="font-weight-normal">  (85) 98408-3976</span><br />
        </p>
        <hr className="line-bottom-container my-5"/>
        <p>
          Protocolo <br />
          <a href="mailto:protocolo.pgm@pgm.fortaleza.ce.gov.br">protocolo.pgm@pgm.fortaleza.ce.gov.br</a>
        </p>
        <p>
          Telefone Móvel: <span className="font-weight-normal">  (85) 3265-7115</span><br />
        </p>
        <hr className="line-bottom-container my-5"/>
        <p>
          Procuradoria da Administração Indireta <br />
          <a href="mailto:protocolo.pgm@pgm.fortaleza.ce.gov.br">andre.leite@pgm.fortaleza.ce.gov.br</a>
        </p>
        <hr className="line-bottom-container my-5"/>
        <p>
          Procuradoria de Licitações, Contratos Administrativos e Controle Externo <br />
          <a href="mailto:prolic@pgm.fortaleza.ce.gov.br">prolic@pgm.fortaleza.ce.gov.br</a>
        </p>
      </div>
    </Layout >
  )
}
